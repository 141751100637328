//TODO: Consider REDACT

//Classes
import {EditablePropertyMetadata} from "classes/gridslate/EditablePropertyMetadata";

//Assuming only data property needs to be stringified
const stringifyDataProperty = (object: any) => {
    let model = { ...object };
    if (model.data) {
        model.data = JSON.stringify(object.data);
    }
    return model;
}

const stringifyDataPropertyOnList = (objects: any[]) => {
    let parsedObjects = [] as any[];
    objects.forEach((object) => {
        parsedObjects.push(stringifyDataProperty(object));
    });
    return parsedObjects;
}

const unstringifyDataProperty = (object: any) => {
    let model = { ...object };
    if (model.data) {
        model.data = JSON.parse(object.data);
    }
    return model;
}

const unstringifyDataPropertyOnList = (objects: any[]) => {
    let parsedObjects = [] as any[];
    objects.forEach((object) => {
        parsedObjects.push(unstringifyDataProperty(object));
    });
    return parsedObjects;
}

const setStatusPropertyOnList = (objects: any[], status: string) => {
    let parsedObjects = [] as any[];
    objects.forEach((object) => {
        let model = { ...object };
        model.status = status;
        parsedObjects.push(model);
    });
    return parsedObjects;
}

const parseGenericFetchedPayload = (objects: any[]) => {
    let parsedObjects = [] as any[];
    objects.forEach((object) => {
        parsedObjects.push(unstringifyDataProperty(object));
    });
    return parsedObjects;
}

// const parseGenericList = (objects: any[]) => {
//     let parsedObjects = [] as any[];
//     objects.forEach((object) => {
//         parsedObjects.push(unstringifyDataProperty(object));
//     });
//     return parsedObjects;
// }

const getObjectName = (objectType: string, object: any) => {
    let name = "";
    if (object.name) {
        name = object.name;
    } else if (object.title) {
        name = object.title;
    } else if (object.type) {
        name = object.type;
    } else {
        name = object.id;
    }
    
    return name;
}

const parseObjectUpdate = (thisObject: any, propertyList: EditablePropertyMetadata[]) => {

    function updateObject(obj: any, updates: { propertyName: string, value: any }[]) {
        for (let update of updates) {
            obj[update.propertyName] = update.value;
        }
        return obj;
    }

    thisObject = updateObject(thisObject, propertyList);
    return thisObject;

}

export default {
    parseGenericFetchedPayload,
    getObjectName,
    stringifyDataProperty,
    unstringifyDataProperty,
    stringifyDataPropertyOnList,
    unstringifyDataPropertyOnList,
    setStatusPropertyOnList,
    parseObjectUpdate
};