//React
import React from 'react';
import CodeblockViewer from 'views/courseview/courseTextElements/CodeblockElement';

//UI

//Services

//Logics

//Components
import RenderContent from "views/render/RenderNodeContent";

//Store

//Classes

type Props = {
    node: any;
    elementId: string;
    enableTryIt: boolean;
}

//This function will take a node JSON and parse it into HTML
const RenderNode = (props: Props) => {

    const { node, enableTryIt } = props;
    let elementId = node.attrs.elementId;
    //console.log("Render", node);

    return (
        <>
            {node.type === "heading" && node.attrs.level === 1 && <h1 id={"node-" + elementId}><RenderContent content={node.content[0]} elementId={elementId}></RenderContent></h1>}
            {node.type === "heading" && node.attrs.level === 2 && <h2 id={"node-" + elementId}><RenderContent content={node.content[0]} elementId={elementId}></RenderContent></h2>}
            {node.type === "heading" && node.attrs.level === 3 && <h3 id={"node-" + elementId}><RenderContent content={node.content[0]} elementId={elementId}></RenderContent></h3>}
            {node.type === "heading" && node.attrs.level === 4 && <h4 id={"node-" + elementId}><RenderContent content={node.content[0]} elementId={elementId}></RenderContent></h4>}
            {node.type === "heading" && node.attrs.level === 5 && <h5 id={"node-" + elementId}><RenderContent content={node.content[0]} elementId={elementId}></RenderContent></h5>}
            {node.type === "heading" && node.attrs.level === 6 && <h6 id={"node-" + elementId}><RenderContent content={node.content[0]} elementId={elementId}></RenderContent></h6>}
            {node.type === "paragraph" && node.content && <p id={elementId}>{node.content.map((content: any, index: number) => {
                return <React.Fragment key={"node-" + elementId + index}>
                    <RenderContent content={content} elementId={elementId}></RenderContent>
                </React.Fragment>
            })}</p>}
            {node.type === "bulletList" && <ul id={"node-" + elementId}>{node.content.map((listItem: any, index: number) => {
                return <li key={"node-" + elementId + index}>{listItem.content[0].content.map((content: any, index2: number) => {
                    return <React.Fragment key={"node-listitem-" + elementId + index2}>
                        <RenderContent content={content} elementId={elementId}></RenderContent>
                    </React.Fragment>
                })}</li>;
            })}</ul>}
            {node.type === "blockquote" && <blockquote id={elementId}>{node.content[0].content.map((content: any, index: number) => {
                return <React.Fragment key={"node-" + elementId + index}>
                    <RenderContent content={content} elementId={elementId}></RenderContent>
                </React.Fragment>
            })}</blockquote>}
            {node.type === "orderedList" && <ol id={elementId}>{node.content.map((listItem: any, index: number) => {
                return <li key={"node-" + elementId + index}>{listItem.content[0].content.map((content: any, index2: number) => {
                    return <React.Fragment key={"node-listitem-" + elementId + index2}>
                        <RenderContent content={content} elementId={elementId}></RenderContent>
                    </React.Fragment>
                })}</li>;
            })}</ol>}
            {/* {node.type === "codeBlock" && <pre id={"node-" + elementId}><code>{node.content[0].text}</code></pre>} */}
            {node.type === "codeBlock" && <CodeblockViewer code={node.content[0].text} enableTryIt={enableTryIt} ></CodeblockViewer>}
            {node.type === "image" && <img id={"node-" + elementId} src={node.attrs.src} alt={node.attrs.alt} title={node.attrs.title}></img>}

            {/* {node.type === "checkpoint" && <ContinueCheckpoint elementId={elementId}></ContinueCheckpoint>} */}

        </>
    )
}

export default RenderNode;
