//React
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Components / helpers 
import { Button, Card, Breadcrumb, ToggleSwitch, Modal, Tooltip, TextInput, Alert, Select, Avatar } from "flowbite-react";
import { HiInformationCircle, HiHome, HiOutlineExclamationCircle } from "react-icons/hi";
import courseIcons from 'view_components/icons/courseIcons';
import flashSetLogic from "logic/synapp/flashSetLogic";

//Services
import useApiGet from "services/useApiGet";
import config from "config";
import apiService from "services/apiService";
import errorService from "services/errorService";
import { toast } from "react-toastify";
import QRCode from 'react-qr-code'; //TODO: Implement QR code for sharing games

//Store
import { userAtom } from 'atom';
import { useAtom } from 'jotai';

//Views/Components
import CreateWordFlashSetModal from "./CreateWordFlashSetModal";
import CreateQAFlashSetModal from "./CreateQAFlashSetModal";

//Classes
import { WordFlashSet, WordSet } from "classes/synapp/wordflash/WordFlashSet";
import { CourseTemplate } from "classes/course/CourseTemplate";
import { QAFlashSet } from "classes/synapp/wordflash/QAFlashSet";

function CourseTemplateBrowser() {

  //=================================================================================================== SIMPLE HELPER CLASSES
  class CurrentAction {
    type: string = "";
    id: string;
    isSaving: boolean = false;
    isLoading: boolean = false;
    isCloning: boolean = false;
    isDeleting: boolean = false;
    isEditing: boolean = false;

    constructor(type: string, id: string) {
      this.type = type;
      this.id = id;
      if (type === 'saving') {
        this.isSaving = true;
      }
      if (type === 'loading') {
        this.isLoading = true;
      }
      if (type === 'cloning') {
        this.isCloning = true;
      }
      if (type === 'deleting') {
        this.isDeleting = true;
      }
      if (type === 'editing') {
        this.isEditing = true;
      }
    }

    reset = () => {
      this.type = '';
      this.isSaving = false;
      this.isLoading = false;
      this.isCloning = false;
      this.isDeleting = false;
      this.isEditing = false;
      return this;
    }
  }

  class BreadcrumbItem {
    title: string;
    path: string;
    icon: any;
    constructor(title: string, path: string, icon: any) {
      this.title = title;
      this.path = path;
      this.icon = icon;
    }
  }

  class CloneData {
    type: string;
    index: number;

    constructor(type: string, index: number) {
      this.type = type;
      this.index = index;

    }
  }

  //=================================================================================================== STATE

  const baseLink = 'https://synapp.org/wordflash/stage';
  const { stateEditingOn } = useParams();

  const [path, setPath] = useState<BreadcrumbItem[]>([new BreadcrumbItem('Courses', '/course/browser', HiHome)]);
  const [selectedCourseTemplate, setSelectedCourseTemplate] = useState<CourseTemplate | null>(null);
  const [user] = useAtom(userAtom);
  const [editingOn, setEditingOn] = useState<boolean>(false);
  const [hasEditingPrivileges, setHasEditingPrivileges] = useState<boolean>(false);

  const [filterPublic, setFilterPublic] = useState<boolean>(true);
  const [filterPrivate, setFilterPrivate] = useState<boolean>(false);

  const { payload: publicCourseTemplates, isPending: isPendingCT, error: errorCT } = useApiGet(config.apiUrl, '/cms/GetPublicCourseTemplates');
  const { payload: yourCourseTemplates, isPending: isPendingYCT, error: errorYCT } = useApiGet(config.apiUrl, '/cms/GetCourseTemplates');
  //const [privateCourseTemplates, setPrivateCourseTemplates] = useState<CourseTemplate[]>([]); 
  const [courseTemplates, setCourseTemplates] = useState<CourseTemplate[]>([]);

  const [wordFlashSets, setWordFlashSets] = useState<WordFlashSet[]>([]);
  const [qAFlashSets, setQAFlashSets] = useState<QAFlashSet[]>([]);

  //Modal states
  const [showCreateWordFlashSetModal, setShowCreateWordFlashSetModal] = useState<boolean>(false);
  const [showCreateQAFlashSetModal, setShowCreateQAFlashSetModal] = useState<boolean>(false);
  const [showCreateCourseTemplateModal, setShowCreateCourseTemplateModal] = useState<boolean>(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [showQRModal, setShowQRModal] = useState<boolean>(false);

  //For creating new courses and word flash sets
  const [newCourseTemplate, setNewCourseTemplate] = useState<CourseTemplate>(new CourseTemplate());
  const [tempWordFlashSet, setTempWordFlashSet] = useState<WordFlashSet>(new WordFlashSet('', '', [new WordSet([], 'Set 1')]));
  const [tempQAFlashSet, setTempQAFlashSet] = useState<QAFlashSet>(new QAFlashSet('', ''));

  //For delete confirmation prompt
  const [deleteID, setDeleteID] = useState<string>('');
  const [deleteType, setDeleteType] = useState<string>('');

  //For cloning/copying
  const [showCopyToModal, setShowCopyToModal] = useState<boolean>(false);
  const [cloneData, setCloneData] = useState<CloneData>(new CloneData('', -1));
  const [selectedCopyToCourseTemplateId, setSelectedCopyToCourseTemplateId] = useState<string>("");

  const [isSaving, setIsSaving] = useState<boolean>(false);
  //const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentAction, setCurrentAction] = useState<CurrentAction>(new CurrentAction('loading', 'courses')); //For spinners

  const [qrCodeLink, setQRCodeLink] = useState<string>('');

  const navigate = useNavigate();

  //=================================================================================================== USE EFFECTS

  useEffect(() => {
    if (publicCourseTemplates && yourCourseTemplates) {
      applyFilters(filterPublic, filterPrivate);
      setCurrentAction(currentAction.reset());
      //setCourseTemplates(publicCourseTemplates);
    }
  }, [publicCourseTemplates, yourCourseTemplates]);

  useEffect(() => {
    applyFilters(filterPublic, filterPrivate);
  }, [filterPublic, filterPrivate]);

  // useEffect(() => {
  //   //if user logged in make a call to get their course templates
  //   if (user.loggedIn) {
  //     const getYourCourseTemplates = async () => {
  //       let response = await apiService.get(config.apiUrl + '/cms/GetCourseTemplates');
  //       if (response.success) {
  //         setPrivateCourseTemplates(response.payload);
  //         //console.log(response.payload);
  //         applyFilters(filterPublic, filterPrivate);
  //       } else {
  //         //Error reported from server
  //         toast.error(response.message);
  //         console.log(response.message);
  //       }
  //     }
  //     getYourCourseTemplates();
  //   }

  // }, [user]);

  useEffect(() => {
    if (user.loggedIn) {
      if (user.claims.includes('Master') || user.claims.includes('Teacher')) {
        setHasEditingPrivileges(true);
      }
      setFilterPrivate(true);
    }
  }, [user]);

  useEffect(() => {
    if (stateEditingOn && stateEditingOn === 'true') {
      setEditingOn(true);
    }
  }, [stateEditingOn]);
  //=================================================================================================== CRUD I/O


  const handleCreateUpdateCourseTemplate = async (courseTemplate: CourseTemplate) => {
    let response;
    setIsSaving(true);

    if (courseTemplate.id === '') {
      //Create a new slideshow
      response = await apiService.post(config.apiUrl + '/cms/CreateCourseTemplate', courseTemplate);
      if (response.success) {
        toast.success("Course created successfully!");
        setCourseTemplates([...courseTemplates, response.payload]);
      } else {
        //Error reported from server
        errorService.handleError(response);
        //toast.error(response.message);
        //console.log(response.message);
      }
      setIsSaving(false);
    } else {
      //Update existing course template
      response = await apiService.post(config.apiUrl + '/cms/UpdateCourseTemplate', courseTemplate);
      if (response.success) {
        toast.success("Course updated successfully!");
        let newCourseTemplates = [...courseTemplates];
        let index = newCourseTemplates.findIndex((cTemplate: CourseTemplate) => cTemplate.id === courseTemplate.id);
        newCourseTemplates[index] = response.payload;
        setCourseTemplates(newCourseTemplates);
      } else {
        //Error reported from server
        //toast.error(response.message);
        //console.log(response.message);
        errorService.handleError(response);
      }
      setIsSaving(false);
    }

  }

  const handleEditCourseTemplate = (index: number) => {
    setNewCourseTemplate(courseTemplates[index]);
    setShowCreateCourseTemplateModal(true);
  }

  const handleCloneCourseTemplate = async (type: string, index: number) => {
    setCurrentAction(new CurrentAction('cloning', courseTemplates[index].id));
    let response = await apiService.get(config.apiUrl + '/cms/CloneCourseTemplate/' + courseTemplates[index].id);
    if (response.success) {
      toast.success("Course template cloned successfully!");
      setCourseTemplates([...courseTemplates, response.payload]);
      setCurrentAction(new CurrentAction('none', ''));
    } else {
      errorService.handleError(response);
      setCurrentAction(new CurrentAction('none', ''));
    }

  }

  const handlePersistWordFlashSet = async (wordFlashSet: WordFlashSet) => {
    //TODO: persist to db
    if (selectedCourseTemplate == null) {
      toast.error("Please select a course template first");
      return;
    }

    if (wordFlashSet.id !== "") {
      //Update word flash game
      let response = await apiService.post(config.apiUrl + '/cms/UpdateWordFlashSet', wordFlashSet);
      if (response.success) {
        toast.success("Word flash set updated successfully!");
        let newWordFlashSets = [...wordFlashSets];
        let index = newWordFlashSets.findIndex((thisSet: WordFlashSet) => thisSet.id === wordFlashSet.id);
        newWordFlashSets[index] = response.payload;
        setWordFlashSets(newWordFlashSets);
        setShowCreateWordFlashSetModal(false);
      } else {
        errorService.handleError(response);
      }
    } else {

      let response = await apiService.post(config.apiUrl + '/cms/CreateWordFlashSet', wordFlashSet);

      if (response.success) {
        toast.success("Word flash game created successfully!");
        let newWordFlashSets = [...wordFlashSets];
        newWordFlashSets.push(response.payload);
        setWordFlashSets(newWordFlashSets);
        setShowCreateWordFlashSetModal(false);
      } else {
        errorService.handleError(response);
      }
    }
  }

  const handlePersistQAFlashSet = async (qaFlashSet: QAFlashSet) => {
    console.log(qaFlashSet);
    if (selectedCourseTemplate == null) {
      toast.error("Please select a course template first");
      return;
    }

    if (qaFlashSet.id !== "") {
      //Update word flash game
      let response = await apiService.post(config.apiUrl + '/cms/UpdateQAFlashSet', qaFlashSet);
      if (response.success) {
        toast.success("QA flash set updated successfully!");
        let newQAFlashSets = [...qAFlashSets];
        let index = newQAFlashSets.findIndex((qaFlashSet: QAFlashSet) => qaFlashSet.id === qaFlashSet.id);
        newQAFlashSets[index] = response.payload;
        setQAFlashSets(newQAFlashSets);
        setShowCreateQAFlashSetModal(false);
      } else {
        //Error reported from server
        //toast.error(response.message);
        //console.log(response.message);
        errorService.handleError(response);
      }
    } else {

      let response = await apiService.post(config.apiUrl + '/cms/CreateQAFlashSet', qaFlashSet);

      if (response.success) {
        toast.success("QA flash game created successfully!");
        let newQAFlashSets = [...qAFlashSets];
        newQAFlashSets.push(response.payload);
        setQAFlashSets(newQAFlashSets);
        setShowCreateQAFlashSetModal(false);
      } else {
        //Error reported from server
        //toast.error(response.message);
        //console.log(response.message);
        errorService.handleError(response);
      }
    }

  }

  const handleCreateFlashSet = (type: string) => {
    if (selectedCourseTemplate === null) {
      toast.error("Please select a course template first");
      return;
    }

    if (type === 'word') {
      let newWordFlashSet = new WordFlashSet(selectedCourseTemplate.id, '', [new WordSet([], 'Set 1')]);
      newWordFlashSet.isPublic = selectedCourseTemplate.isPublic;
      setTempWordFlashSet(newWordFlashSet);
      setShowCreateWordFlashSetModal(true);
    } else if (type === 'qa') {
      let newQAFlashSet = new QAFlashSet(selectedCourseTemplate.id, '');
      newQAFlashSet.isPublic = selectedCourseTemplate.isPublic;
      setTempQAFlashSet(newQAFlashSet);
      setShowCreateQAFlashSetModal(true);
    }
  }

  const handleCloneFlashSet = async (type: string, index: number, toCourseTemplateId: string) => {
    setCurrentAction(new CurrentAction('cloning', type === 'word' ? wordFlashSets[index].id : qAFlashSets[index].id));
    if (type === 'word') {
      let response = await apiService.get(config.apiUrl + '/cms/CloneWordFlashSet/' + wordFlashSets[index].id + '/' + toCourseTemplateId);
      if (response.success) {
        toast.success("Word flash set cloned successfully!");
        if (toCourseTemplateId === selectedCourseTemplate?.id) {
          let newWordFlashSets = [...wordFlashSets];
          newWordFlashSets.push(response.payload);
          setWordFlashSets(newWordFlashSets);
        }
      } else {
        errorService.handleError(response);
      }
      setCurrentAction(new CurrentAction('none', ''));
    } else if (type === 'qa') {
      let response = await apiService.get(config.apiUrl + '/cms/CloneQAFlashSet/' + qAFlashSets[index].id + '/' + toCourseTemplateId);
      if (response.success) {
        toast.success("QA flash set cloned successfully!");
        if (toCourseTemplateId === selectedCourseTemplate?.id) {
          let newQAFlashSets = [...qAFlashSets];
          newQAFlashSets.push(response.payload);
          setQAFlashSets(newQAFlashSets);
        }
      } else {
        errorService.handleError(response);
      }
      setCurrentAction(new CurrentAction('none', ''));
    }

  }

  const handleEditFlashSet = (type: string, index: number) => {
    if (type === 'word') {
      setTempWordFlashSet(wordFlashSets[index]);
      setShowCreateWordFlashSetModal(true);
    } else if (type === 'qa') {
      setTempQAFlashSet(qAFlashSets[index]);
      setShowCreateQAFlashSetModal(true);
    }
  }

  const handleDeleteCourseTemplate = async () => {
    setCurrentAction(new CurrentAction('deleting', deleteID));
    let response = await apiService.del(config.apiUrl + '/cms/DeleteCourseTemplate/' + deleteID);
    if (response.success) {
      toast.success("Course template deleted successfully!");
      let newCourseTemplates = [...courseTemplates];
      let index = newCourseTemplates.findIndex((courseTemplate: CourseTemplate) => courseTemplate.id === deleteID);
      newCourseTemplates.splice(index, 1);
      setCourseTemplates(newCourseTemplates);

      //remove word flash games associated with this course template
      //TODO: should be able to remove this because flashsets are loaded on course template open
      let newWordFlashSets = [...wordFlashSets];
      newWordFlashSets = newWordFlashSets.filter((wordFlashSet: WordFlashSet) => wordFlashSet.courseId !== deleteID);
      setWordFlashSets(newWordFlashSets);

    } else {
      errorService.handleError(response);
    }
    setCurrentAction(new CurrentAction('none', ''));
  }

  const handleDeleteWordFlashSet = async () => {
    setCurrentAction(new CurrentAction('deleting', deleteID));
    let response = await apiService.del(config.apiUrl + '/cms/DeleteWordFlashSet/' + deleteID);
    if (response.success) {
      toast.success("Word flash game deleted successfully!");
      let newWordFlashSets = [...wordFlashSets];
      let index = newWordFlashSets.findIndex((wordFlashSet: WordFlashSet) => wordFlashSet.id === deleteID);
      newWordFlashSets.splice(index, 1);
      setWordFlashSets(newWordFlashSets);
    } else {
      errorService.handleError(response);
    }
    setCurrentAction(new CurrentAction('none', ''));
  }

  const handleDeleteQAFlashSet = async () => {
    setCurrentAction(new CurrentAction('deleting', deleteID));
    let response = await apiService.del(config.apiUrl + '/cms/DeleteQAFlashSet/' + deleteID);
    if (response.success) {
      toast.success("QA flash game deleted successfully!");
      let newQAFlashSets = [...qAFlashSets];
      let index = newQAFlashSets.findIndex((qaFlashSet: QAFlashSet) => qaFlashSet.id === deleteID);
      newQAFlashSets.splice(index, 1);
      setQAFlashSets(newQAFlashSets);
    } else {
      errorService.handleError(response);
    }
    setCurrentAction(new CurrentAction('none', ''));
  }

  const handleDelete = async () => {
    if (deleteType === 'course') {
      handleDeleteCourseTemplate();
    } else if (deleteType === 'word') {
      handleDeleteWordFlashSet();
    } else if (deleteType === 'qa') {
      handleDeleteQAFlashSet();
    }

  }

  //=================================================================================================== MODALS





  //=================================================================================================== FUNCTIONS



  const loadCourseTemplate = async (courseTemplate: CourseTemplate) => {
    setCurrentAction(new CurrentAction('loading', 'games'));
    let newPathItem = new BreadcrumbItem(courseTemplate.title, '', null);
    if (!selectedCourseTemplate) {
      setPath(path.concat(newPathItem));
    } else {
      setPath(path.slice(0, path.length - 1).concat(newPathItem));
    }
    setSelectedCourseTemplate(courseTemplate);

    let response = await apiService.get(config.apiUrl + '/cms/GetGamesByCourseTemplateId/' + courseTemplate.id);
    if (response.success) {
      //setQuestionAnswerFlashGames(response.payload.qAFlashSets);
      setQAFlashSets(response.payload.qaFlashSets);
      setWordFlashSets(response.payload.wordFlashSets);
    } else {
      //toast.error(response.message);
      errorService.handleError(response);
    }
    setCurrentAction(currentAction.reset());
  }

  const startFlashGame = async (type: string, index: number) => {
    if (type === 'qa') {
      //navigate('/wordflash/run', { state: { gameType: 'qa', gameObject: qAFlashSets[index] } });
      navigate('/wordflash/stage/qa/' + qAFlashSets[index].id);
    }
    if (type === 'word') {
      //navigate('/wordflash/run', { state: { gameType: 'word', gameObject: wordFlashSets[index] } });
      navigate('/wordflash/stage/word/' + wordFlashSets[index].id);
    }
  }

  //=================================================================================================== HELPER

  const applyFilters = (filterPublic: boolean, filterPrivate: boolean) => {
    if (!publicCourseTemplates || !yourCourseTemplates) {
      return;
    }
    if (filterPublic && filterPrivate) {
      let allCourseTemplates = [...publicCourseTemplates].concat([...yourCourseTemplates]) as CourseTemplate[];
      //remove duplicates
      allCourseTemplates = allCourseTemplates.filter((courseTemplate, index, self) =>
        index === self.findIndex((t) => (
          t.id === courseTemplate.id
        ))
      );
      setCourseTemplates(allCourseTemplates);
    } else if (filterPublic) {
      setCourseTemplates(publicCourseTemplates);
    } else if (filterPrivate) {
      setCourseTemplates(yourCourseTemplates);
    } else {
      setCourseTemplates([]);
    }
  }

  //=================================================================================================== UI TOOLBARS AND MODALS

  const renderBreadcrumbs = () => {
    return (
      <div>
        <Breadcrumb className="bg-gray-50 px-5 py-6 dark:bg-gray-800 mb-3">
          {path.map((item: BreadcrumbItem, index: number) => (
            <Breadcrumb.Item className='text-2xl' key={item.title} href={item.path} icon={item.icon}>
              {item.title}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
    );
  }

  const renderFilterToolbar = () => {
    return (
      <div className='grid grid-cols-3'>
        
        {!selectedCourseTemplate && <div className='flex align-middle mb-3'>
          {/* <span className='text-xl font-bold mr-3'>
            Filter:
          </span> */}
          <Button size='sm' pill outline={!filterPublic}
            className={filterPublic ? 'ml-3 text-2xl bg-col-s1 mr-3' : 'text-xs bg-col-p2 mr-3 line-through decoration-col-p2 decoration-2'}
            onClick={() => { applyFilters(!filterPublic, filterPrivate); setFilterPublic(!filterPublic) }}
          ><span className='mr-3'>{courseIcons.renderOpenHandsIcon(24, 24)}</span> Public Courses</Button>
          <Button size='sm' pill outline={!filterPrivate}
            className={filterPrivate ? 'bg-col-s1 mr-3' : 'bg-col-p2 mr-3 line-through decoration-col-p2 decoration-2'}
            onClick={() => { applyFilters(filterPublic, !filterPrivate); setFilterPrivate(!filterPrivate) }}
          ><span className='mr-3'>{courseIcons.renderLockedIcon(24, 24)}</span>Your Courses</Button>
        </div>}
        {selectedCourseTemplate && <h1 className='text-xl md:text-2xl my-2 font-bold'>Course: {selectedCourseTemplate.title}</h1>}
        <div></div>
        {hasEditingPrivileges && <div className='relative h-10'>
          <ToggleSwitch color='gray' className='absolute right-2' checked={editingOn} label={editingOn ? 'Editing on' : 'Editing off'} onChange={() => setEditingOn(!editingOn)} />
        </div>}
      </div>

      // <div className=''>
      //   {hasEditingPrivileges && <div className='relative h-10'>
      //     <ToggleSwitch color='gray' className='absolute right-2' checked={editingOn} label={editingOn ? 'Editing on' : 'Editing off'} onChange={() => setEditingOn(!editingOn)} />
      //   </div>}
      //   {!selectedCourseTemplate && <div className='flex align-middle mb-3'>
      //     <span className='text-xl font-bold mr-3'>
      //       Filter:
      //     </span>
      //     <Button size='sm' pill outline={!filterPublic}
      //       className={filterPublic ? 'bg-col-s1 mr-3' : 'bg-col-p2 mr-3 line-through decoration-col-p2 decoration-2'}
      //       onClick={() => { applyFilters(!filterPublic, filterPrivate); setFilterPublic(!filterPublic) }}
      //     ><span className='mr-3'>{courseIcons.renderOpenHandsIcon(24, 24)}</span> Public Courses</Button>
      //     <Button size='sm' pill outline={!filterPrivate}
      //       className={filterPrivate ? 'bg-col-s1 mr-3' : 'bg-col-p2 mr-3 line-through decoration-col-p2 decoration-2'}
      //       onClick={() => { applyFilters(filterPublic, !filterPrivate); setFilterPrivate(!filterPrivate) }}
      //     ><span className='mr-3'>{courseIcons.renderLockedIcon(24, 24)}</span>Your Courses</Button>
      //   </div>}
      //   {selectedCourseTemplate && <h1 className='text-xl md:text-2xl my-2 font-bold'>Course: {selectedCourseTemplate.title}</h1>}
      // </div>


      // <div className='relative h-16'>
      //   {!selectedCourseTemplate && <div className='flex absolute left-0 align-middle'>
      //     <span className='text-xl font-bold mr-3'>
      //       Filter:
      //     </span>
      //     <Button pill outline={!filterPublic}
      //       className={filterPublic ? 'bg-col-s1 mr-3' : 'bg-col-p2 mr-3 line-through decoration-col-p2 decoration-2'}
      //       onClick={() => { applyFilters(!filterPublic, filterPrivate); setFilterPublic(!filterPublic) }}
      //     ><span className='mr-3'>{courseIcons.renderOpenHandsIcon(24, 24)}</span> Public Courses</Button>
      //     <Button pill outline={!filterPrivate}
      //       className={filterPrivate ? 'bg-col-s1 mr-3' : 'bg-col-p2 mr-3 line-through decoration-col-p2 decoration-2'}
      //       onClick={() => { applyFilters(filterPublic, !filterPrivate); setFilterPrivate(!filterPrivate) }}
      //     ><span className='mr-3'>{courseIcons.renderLockedIcon(24, 24)}</span>Your Courses</Button>
      //   </div>}
      //   {selectedCourseTemplate && <h1 className='text-xl md:text-2xl my-2 font-bold'>Course: {selectedCourseTemplate.title}</h1>}
      //   {hasEditingPrivileges && <ToggleSwitch color='gray' className='absolute right-0' checked={editingOn} label={editingOn ? 'Editing on' : 'Editing off'} onChange={() => setEditingOn(!editingOn)} />}
      // </div>
    )
  }

  const renderConfirmDeleteModal = () => {
    return (<Modal show={showConfirmDeleteModal} size="md" onClose={() => setShowConfirmDeleteModal(false)} popup>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {deleteType === 'course' ?
              'Are you sure you want to delete this course template? All associated word flash sets will also be deleted.'
              : 'Are you sure you want to delete this Wordflash set?'}
          </h3>
          <div className="flex justify-center gap-4">
            {/* <Button color="failure" onClick={() => { setShowConfirmDeleteModal(false); deleteType === 'template' ? handleDeleteCourseTemplate() : handleDeleteWordFlashSet() }}> */}
            <Button color="failure" onClick={() => { setShowConfirmDeleteModal(false); handleDelete() }}>
              {"Yes, I'm sure"}
            </Button>
            <Button color="gray" onClick={() => setShowConfirmDeleteModal(false)}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>);
  }


  const renderQRCodeModal = () => {
    return (
      <Modal show={showQRModal} size="6xl" onClose={() => { setShowQRModal(false) }} popup>
        <Modal.Header>Launch Flashset Game</Modal.Header>
        <Modal.Body>
          <div className='flex flex-col items-center'>
            <QRCode value={qrCodeLink} />
            <p className='text-3xl my-6'>Share this link if you want your students to launch individual games</p>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  //render icons and information for the relevant card
  const renderCourseInfoBar = (type: string, object: CourseTemplate) => {

    if (type === 'course') {
      return (
        <div className='grid grid-cols-3 place-items-center'>
          <div className='border-4 p-2 border-col-p1'>{courseIcons.renderCourseIcon()}</div>
          {/* <div className='inline-flex'>{courseIcons.renderBrainIcon()}</div> */}
          {!object.isPublic && <Tooltip className="transition-opacity delay-700" content="Private">
            <div>{courseIcons.renderLockedIcon()}</div>
          </Tooltip>}
          {object.isPublic && <Tooltip className="transition-opacity delay-700" content="Publicly accessible">
            <div>{courseIcons.renderOpenHandsIcon()}</div>
          </Tooltip>}
          {object.creatorId === user.id && <Tooltip className="transition-opacity delay-700" content="Yours!">
            <Avatar className='cursor-default' alt="Owner" placeholderInitials={user?.initials} rounded />
          </Tooltip>}
        </div>
      )
    }

  }

  const renderCreateCourseTemplateModal = () => {

    return (
      <Modal show={showCreateCourseTemplateModal} onClose={() => setShowCreateCourseTemplateModal(false)}>
        <Modal.Header>Create a new Course Template</Modal.Header>
        <Modal.Body>
          <div className='flex flex-col'>
            <div className='mb-3'>
              <span>Title </span>
              <TextInput type='text' placeholder='Title' value={newCourseTemplate.title} onChange={(e) => setNewCourseTemplate({ ...newCourseTemplate, title: e.target.value })} />
            </div>
            <div className='mb-5'>
              <span>Description </span>
              <TextInput type='text' placeholder='Description (optional)' value={newCourseTemplate.description} onChange={(e) => setNewCourseTemplate({ ...newCourseTemplate, description: e.target.value })} />
            </div>
            <ToggleSwitch
              checked={newCourseTemplate.isPublic}
              label={newCourseTemplate.isPublic ? "Course template is public. Anyone can see it." : "Course template is private. Only you can see it."}
              onChange={() => setNewCourseTemplate({ ...newCourseTemplate, isPublic: !newCourseTemplate.isPublic })}
            />
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={newCourseTemplate.title === ''}
            onClick={() => {
              setShowCreateCourseTemplateModal(false);
              handleCreateUpdateCourseTemplate(newCourseTemplate);
            }
            }>{newCourseTemplate.id === '' ? 'Create New Course Template' : 'Update Course Template'}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const renderCopyToModal = () => {

    return (
      <Modal show={showCopyToModal} size="4xl" onClose={() => { setShowCopyToModal(false) }} popup>
        <Modal.Header>Copy To</Modal.Header>
        <Modal.Body>
          <div className='flex flex-col items-center'>
            <p className='text-3xl my-6'>Which course template do you want to copy this to?</p>
            {/* //Create select dropdown of all course templates owned by this user. Select current course template by default */}
            <Select id="copy-to-modal-select" value={selectedCopyToCourseTemplateId} onChange={(e) => setSelectedCopyToCourseTemplateId(e.target.value)}>
              {selectedCopyToCourseTemplateId === "None" && <option value="None">Select a course template</option>}
              {yourCourseTemplates?.map((courseTemplate: CourseTemplate, index: number) => (
                <option key={courseTemplate.id} value={courseTemplate.id} >{courseTemplate.title}</option>
              ))}
            </Select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={selectedCopyToCourseTemplateId === 'None'}
            onClick={() => {
              setShowCopyToModal(false);

              handleCloneFlashSet(cloneData.type, cloneData.index, selectedCopyToCourseTemplateId);
              // handleCreateUpdateCourseTemplate(newCourseTemplate);
            }
            }>Copy</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  // const renderEditToolButton = (type: string, index: number, object: any) => {

  const renderEditToolbar = (type: string, index: number, object: any) => {

    let objectId = "";
    let creatorId = object.creatorId;
    if (type === 'word') {
      objectId = wordFlashSets[index].id;
    }
    if (type === 'qa') {
      objectId = qAFlashSets[index].id;
    }
    if (type === 'course') {
      objectId = courseTemplates[index].id;
    }

    const handleEdit = () => {
      if (type === 'course') {
        handleEditCourseTemplate(index);
      }
      if (type === 'word') {
        handleEditFlashSet('word', index);
      }
      if (type === 'qa') {
        handleEditFlashSet('qa', index);
      }
    }

    const handleClone = () => {
      let val = selectedCourseTemplate?.isPublic ? "None" : selectedCourseTemplate?.id;
      if (val === undefined) val = "None";
      setSelectedCopyToCourseTemplateId(val);

      if (type === 'course') {
        handleCloneCourseTemplate(type, index);
      }
      if (type === 'word' || type === 'qa') {
        setCloneData(new CloneData(type, index));
        setShowCopyToModal(true);
        //handleCloneFlashSet(type, index);
      }
      // if (type === 'word') {
      //   handleCloneFlashSet(type, index);
      //   if (wordFlashSets[index].creatorId !== user.id) {
      //     toast.error("You can only clone your own word flash sets \n We are working on a feature to allow you to clone public word flash sets.");
      //     return;
      //   }
      // }
      // if (type === 'qa') {
      //   handleCloneFlashSet(type, index);
      // }
    }

    return (
      <>
        <div className='grid grid-cols-3 place-items-center'>

          {creatorId === user.id &&
            <Tooltip className="transition-opacity delay-700" content="Edit">
              <Button
                size='xs' className='bg-col-p2' outline
                disabled={currentAction.id === objectId}
                onClick={() => handleEdit()}>
                {(currentAction.id !== objectId || (currentAction.id === objectId && !currentAction.isEditing)) && courseIcons.renderEditIcon()}
                {currentAction.id === objectId && currentAction.isEditing && <HiOutlineExclamationCircle className='animate-spin h-6 w-6 text-red-500' />}
              </Button>
            </Tooltip>}

          <Tooltip className="transition-opacity delay-700" content="Copy to">
            <Button
              size='xs' className='bg-col-p2' outline
              disabled={currentAction.id === objectId}
              onClick={() => { handleClone() }}>
              {(currentAction.id !== objectId || (currentAction.id === objectId && !currentAction.isCloning)) && courseIcons.renderCopyIcon()}
              {currentAction.id === objectId && currentAction.isCloning && <HiOutlineExclamationCircle className='animate-spin h-6 w-6 text-red-500' />}
            </Button>
          </Tooltip>

          {creatorId === user.id &&
            <Tooltip className="transition-opacity delay-700" content="Delete">
              <Button
                size='xs' className='' outline color='failure'
                disabled={currentAction.id === objectId}
                onClick={() => {
                  setShowConfirmDeleteModal(true);
                  setDeleteID(objectId);
                  setDeleteType(type);
                }}>
                {(currentAction.id !== objectId || (currentAction.id === objectId && !currentAction.isDeleting)) && courseIcons.renderDeleteIcon()}
                {currentAction.id === objectId && currentAction.isDeleting && <HiOutlineExclamationCircle className='animate-spin h-6 w-6 text-red-500' />}
              </Button>
            </Tooltip>}
        </div>
        {/* {currentAction.id !== objectId && <div className='flex items-center space-x-3 m-auto'>
        
          {creatorId === user.id &&
            <Tooltip className="transition-opacity delay-700" content="Edit">
              <Button size='xs' className='bg-col-p2 inline-flex' outline onClick={() => handleEdit()}>{courseIcons.renderEditIcon()}</Button>
            </Tooltip>}
         
          <Tooltip className="transition-opacity delay-700" content="Copy to">
            <Button size='xs' className='bg-col-p2 inline-flex' outline onClick={() => { handleClone() }}>{courseIcons.renderCopyIcon()}</Button>
          </Tooltip>
         
          {creatorId === user.id &&
            <Tooltip className="transition-opacity delay-700" content="Delete">
              <Button size='xs' className='inline-flex' outline color='failure' onClick={() => {
                setShowConfirmDeleteModal(true);
                setDeleteID(objectId);
                setDeleteType(type);
              }}>{courseIcons.renderDeleteIcon()}</Button>
            </Tooltip>}
        </div>}
        
        {currentAction.id === objectId && <div className='flex items-center space-x-3 m-auto'>
          {creatorId === user.id && <Button size='xs' className='inline-flex' outline disabled >
            {currentAction.type !== 'editing' ? courseIcons.renderEditIcon() :
              <HiOutlineExclamationCircle className='animate-spin h-6 w-6 text-red-500' />}
          </Button>}
          <Button size='xs' className='inline-flex' outline disabled >
            {currentAction.type !== 'cloning' ? courseIcons.renderCopyIcon() :
              <HiOutlineExclamationCircle className='animate-spin h-6 w-6 text-red-500' />}
          </Button>
          {creatorId === user.id && <Button size='xs' className='inline-flex' outline disabled color='failure' >
            {currentAction.type !== 'deleting' ? courseIcons.renderDeleteIcon() :
              <HiOutlineExclamationCircle className='animate-spin h-6 w-6 text-red-500' />}
          </Button>}

        </div>} */}
      </>
    )

    // <div className='flex h-8 items-center space-x-3 m-auto'>

    //             <Button className='inline-flex w-20' outline onClick={() => handleEditCourseTemplate(index)}>Edit</Button>
    //             <Button size='sm' outline color='failure' onClick={() => {
    //               setShowConfirmDeleteModal(true);
    //               setDeleteID(courseTemplates[index].id);
    //               setDeleteType('template');
    //             }}>Delete</Button>

    //           </div>
  }

  const renderLoadingCard = () => {
    return (<Card className="max-w-md border-dotted cursor-pointer">
      <span className='inline-flex  text-lg h-16 md:text-2xl font-bold text-center text-gray-400'>Loading...</span>
    </Card>)
  }

  //=================================================================================================== RENDER

  return (
    <div className='container mx-auto'>

      {/* //TODO: Convert to components */}
      {renderBreadcrumbs()}
      {renderFilterToolbar()}

      {editingOn && !user.loggedIn && <Alert className='my-8 text-sm md:text-lg' color="failure" icon={HiInformationCircle}>
        <span className="text-sm md:text-lg">Info alert!</span> You are not logged in so you cannot create new course templates or flash sets.
        Log in or create an account to continue. <a href='/accounts/login' className='underline'>Click here to log in</a>
      </Alert>}

      {!selectedCourseTemplate &&
        <div className='grid grid-cols-2 gap-2 md:grid-cols-6 lg:grid-col-6'>
          {currentAction.isLoading && currentAction.id === 'courses' && renderLoadingCard()}
          {courseTemplates && courseTemplates.map((courseTemplate: CourseTemplate, index: number) => (
            <Card className="max-w-md flex" key={courseTemplate.title + index}>
              {renderCourseInfoBar('course', courseTemplate)}
              <div className='flex'>
                <h5 className='text-lg h-16 md:text-2xl font-bold'>{courseTemplate.title}</h5>
              </div>

              <div className='h-32'>
                {/* <div className='text-md'>{courseTemplate.isPublic ? 'This course is public' : 'Your course'}</div>
                <div className='text-md'>{courseTemplate.creatorId === user.id ? 'You own this course' : ''}</div> */}
                <div className="truncate overflow-hidden leading-snug h-16 whitespace-normal">{courseTemplate.description}</div>
              </div>
              <Button className="bg-col-p2" onClick={() => loadCourseTemplate(courseTemplate)}>Open</Button>
              {editingOn && renderEditToolbar('course', index, courseTemplate)}
            </Card>

          ))}
          {editingOn && <Card className="max-w-sm border-dotted cursor-pointer" onClick={() => setShowCreateCourseTemplateModal(true)}>
            <span className='inline-flex text-lg min-h-16 md:text-2xl font-bold text-center text-gray-400'>
              {user.loggedIn && 'Create new course template'}
              {!user.loggedIn && 'Log in to create new course templates'}
            </span>
          </Card>}
        </div>}
      {selectedCourseTemplate && <div>
        {/* <h1 className='text-xl md:text-2xl my-2'>{selectedCourseTemplate.title}</h1> */}

        {/* ======================================================================= WORDFLASH SETS */}

        <h2 className='text-lg md:text-2xl my-2 font-bold'>Wordflash Sets</h2>
        <div className='grid grid-cols-2 gap-2 md:grid-cols-6 lg:grid-col-6'>
          {currentAction.isLoading && currentAction.id === 'games' && renderLoadingCard()}
          {wordFlashSets && wordFlashSets.map((wordset: WordFlashSet, index: number) => (
            <Card className="max-w-sm" key={wordset.title + index}>
              <div className="flex">
                <span className='inline-flex mr-3'>
                  {courseIcons.renderBrainIcon()}
                </span>
                <span className='inline-flex text-lg h-16 md:text-2xl font-bold'>{wordset.title}</span>
              </div>
              <div className="truncate overflow-hidden leading-snug h-16 whitespace-normal ml-3">{flashSetLogic.joinWordFlashSetWords(wordset)}</div>
              {/* //QR Code Icon */}
              <div
                className='cursor-pointer mx-auto'
                onClick={() => { setQRCodeLink(baseLink + '/word/' + wordset.id); setShowQRModal(true) }}
              >{courseIcons.renderQRCodeIcon(32, 32)}
              </div>
              {/* //Launch button */}
              <Button className='bg-col-p2' onClick={() => startFlashGame('word', index)}>Launch</Button>

              {editingOn && renderEditToolbar('word', index, wordset)}
            </Card>

          ))}

          {editingOn && <Card className="max-w-sm min-h-64 border-dotted cursor-pointer" onClick={() => handleCreateFlashSet('word')}>
            <span className='inline-flex  text-lg h-16 md:text-2xl font-bold text-center text-gray-400'>
              {user.loggedIn && 'Create new wordflash set'}
              {!user.loggedIn && 'Log in to create wordflash sets'}
            </span>
          </Card>}
        </div>

        {/* ======================================================================= QA FLASH SETS */}
        <hr className="w-full h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
        <h2 className='text-lg md:text-2xl my-2 font-bold'>Question Answer Flash Sets</h2>
        <div className='grid grid-cols-2 gap-2 md:grid-cols-6 lg: grid-col-6'>
          {qAFlashSets && qAFlashSets.map((qaSet: QAFlashSet, index: number) => (
            <Card className="max-w-sm" key={qaSet.title + index}>
              <div className="flex">
                <span className='inline-flex mr-3'>
                  {courseIcons.renderSpeechIcon()}
                </span>
                <span className='inline-flex  text-lg h-16 md:text-2xl font-bold'>{qaSet.title}</span>
              </div>
              <div className="truncate overflow-hidden leading-snug h-16 whitespace-normal ml-3">{flashSetLogic.joinQASets(qaSet)}</div>
              {/* //QR Code Icon */}
              <div
                className='cursor-pointer mx-auto'
                onClick={() => { setQRCodeLink(baseLink + '/qa/' + qaSet.id); setShowQRModal(true) }}
              >{courseIcons.renderQRCodeIcon(32, 32)}
              </div>
              {/* //Launch button */}
              <Button className="bg-col-p2" onClick={() => startFlashGame('qa', index)}>Launch</Button>
              {editingOn && renderEditToolbar('qa', index, qaSet)}
            </Card>
          ))}

          {editingOn && <Card className="max-w-sm min-h-64 border-dotted cursor-pointer" onClick={() => handleCreateFlashSet('qa')}>
            <span className='inline-flex  text-lg h-16 md:text-2xl font-bold text-center text-gray-400'>
              {user.loggedIn && 'Create new question/answer set'}
              {!user.loggedIn && 'Log in to create question/answer sets'}
            </span>
          </Card>}
        </div>
      </div>}

      {renderCreateCourseTemplateModal()}
      {renderConfirmDeleteModal()}
      {renderQRCodeModal()}
      {renderCopyToModal()}
      {showCreateWordFlashSetModal &&
        <CreateWordFlashSetModal
          showModal={showCreateWordFlashSetModal}
          setShowModal={setShowCreateWordFlashSetModal}
          wordFlashSet={tempWordFlashSet}
          handlePersistWordFlashSet={handlePersistWordFlashSet}
        />}
      {showCreateQAFlashSetModal &&
        <CreateQAFlashSetModal
          showModal={showCreateQAFlashSetModal}
          setShowModal={setShowCreateQAFlashSetModal}
          qAFlashSet={tempQAFlashSet}
          handlePersistWordFlashSet={handlePersistQAFlashSet}
        />}

    </div>
  );


}

export default CourseTemplateBrowser;