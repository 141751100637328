//TODO: Why are these lower case?

enum Class {
    page = "page",
    styleTheme = "styleTheme",
    component = "component",
    mcq = "mcq",
    category = "category",
    pageChallenge = "pageChallenge",
    stoppingPointStatus = "stoppingPointStatus",
    pageChallengeStatus = "pageChallengeStatus",
    codeChallenge = "codeChallenge",
    codeChallengeStatus = "codeChallengeStatus",
    codeExercise = "codeExercise",
    codeExerciseSubmission = "codeExerciseSubmission",
    course = "course",
    pageChallengeInstance = "pageChallengeInstance",
    foo = "foo",
    account = "account",
    mcqSubmittedAnswer = "mcqSubmittedAnswer",
    navbarClass = "navbar",
   
   
    //nodePageProgress = "nodePageProgress",
    //nodeCourse = "nodeCourse",
    courseContent = "courseContent",
    courseEnrollment = "courseEnrollment",
    courseProgress = "courseProgress",
    //contentDocument = "contentDocument",
}

export {Class};