let appDeployment = process.env.NODE_ENV;
//let appDeployment = "testing";
let appSite = "synapp";
let configObject = {
    "apiUrl": "",
    "slideshowWebsocket": "",
    "slideJoinUrl": "",
    "S3Bucket": "",
    "S3Region": "",
    "site" : "",
    "deployment": appDeployment,
    "siteTitle" : "",
    "publicUrl": ""
};

if (appSite === "synapp" && appDeployment === "development") {
    configObject = {
        "apiUrl": "https://localhost:56004",
        //"apiUrl": "https://hiayymq546.execute-api.ap-northeast-2.amazonaws.com/Prod",
        "slideshowWebsocket": "wss://eu30e67qx2.execute-api.ap-northeast-2.amazonaws.com/Prod/",
        "slideJoinUrl": "http://localhost:3000/join/",
        "S3Bucket": "synapp.frontend",
        "S3Region": "https://s3.ap-northeast-2",
        "site" : "synapp",
        "deployment": appDeployment,
        "siteTitle" : "Synapp",
        "publicUrl": "http://localhost:3000"
    }
}

if (appSite === "synapp" && appDeployment === "testing") {
    configObject = {
        //"apiUrl": "https://localhost:56004",
        "apiUrl": "https://91pqd1yr15.execute-api.ap-northeast-2.amazonaws.com/Prod",
        "slideshowWebsocket": "wss://eu30e67qx2.execute-api.ap-northeast-2.amazonaws.com/Prod/",
        "slideJoinUrl": "https://synappslides.com/join/",
        "S3Bucket": "synapp.frontend",
        "S3Region": "https://s3.ap-northeast-2",
        "site" : "synapp",
        "deployment": appDeployment,
        "siteTitle" : "Synapp",
        "publicUrl": "https://testing.synapp.org"
    }
}

if (appSite === "synapp" && appDeployment === "production") {
    configObject = {
        "apiUrl": "https://larll8xata.execute-api.ap-northeast-2.amazonaws.com/Prod",
        "slideshowWebsocket": "wss://eu30e67qx2.execute-api.ap-northeast-2.amazonaws.com/Prod/",
        "slideJoinUrl": "https://synappslides.com/join/",
        "S3Bucket": "synapp.frontend",
        "S3Region": "https://s3.ap-northeast-2",
        "site" : "synapp",
        "deployment": appDeployment,
        "siteTitle" : "Synapp",
        "publicUrl": "https://synapp.org"
    }
}

export default configObject;


